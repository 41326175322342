import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { DEVICE_SIZE, mq } from '@marty-js/design/src/utils/mq';
import Head from 'next/head';
import { NavLink } from './nav-link';

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  grid-column: 2 / 3;
  ul {
    margin: auto;
    max-width: 400px;
    padding: 5px 8px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    a {
      text-decoration: none;
    }
    li.hr {
      flex-basis: 100%;
      height: 0;
    }
    li {
      flex-grow: 1;
      display: inline;
      padding: 4px;
      text-align: center;
    }
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      grid-column: 2 / 9;
    `,
  )}
`;

type PaginatorMode = 'html' | 'dir';
interface PaginatorProps {
  baseUrl: string;
  mode: PaginatorMode;
  info: {
    page: number;
    size: number;
    total: number;
  };
}

type PageProps = {
  page: number;
  baseUrl: string;
  mode: PaginatorMode;
};

const pageLink = ({ page, baseUrl, mode }: PageProps): string => {
  if (page === 1) {
    return baseUrl;
  }

  return baseUrl.replace(/\/$/gm, '') + (mode === 'html' ? `/page-${page}.html` : `/${page}`);
};

function Page({ page, baseUrl, mode }: PageProps) {
  return (
    <li>
      <NavLink href={pageLink({ page, baseUrl, mode })}>{page}</NavLink>
    </li>
  );
}

export const getPages = (page: number, lastPage: number): number[] => {
  const pages: number[] = [];
  let coef = 1;
  let cur = page;
  while (coef <= lastPage) {
    coef *= 10;
    cur = Math.floor(cur / 10);

    for (let i = 0; i < coef; i += coef / 10) {
      const curPage = cur * coef + i;
      if (curPage && curPage <= lastPage && !pages.includes(curPage)) {
        pages.push(curPage);
      }
    }

    if (coef > lastPage || (pages.includes(coef) && coef * 2 > lastPage)) {
      break;
    }
    pages.push(0);
  }

  return pages;
};

export function Paginator({ info, baseUrl, mode }: PaginatorProps) {
  const { page, size, total } = info;

  const lastPage = Math.ceil(total / size);
  const pages = useMemo(() => getPages(page, lastPage), [page, lastPage]);
  const [isPaginator, setPaginator] = useState(true);
  useEffect(() => {
    if (isPaginator) {
      setPaginator(false);
    }
  }, [isPaginator, setPaginator]);

  if (!isPaginator) {
    return null;
  }

  return (
    <Wrapper>
      <ul data-testid="ul-paginator">
        {page > 1 ? (
          <li>
            <NavLink href={pageLink({ page: 1, baseUrl, mode })}>&lt;&lt;</NavLink>
          </li>
        ) : (
          <li>&lt;&lt;</li>
        )}
        {page > 1 ? (
          <>
            <Head>
              <link rel="prev" href={pageLink({ page: page - 1, baseUrl, mode })} />
            </Head>
            <li>
              <NavLink href={pageLink({ page: page - 1, baseUrl, mode })}>&lt;</NavLink>
            </li>
          </>
        ) : (
          <li>&lt;</li>
        )}
        {page < lastPage ? (
          <>
            <Head>
              <link rel="next" href={pageLink({ page: page + 1, baseUrl, mode })} />
            </Head>
            <li>
              <NavLink href={pageLink({ page: page + 1, baseUrl, mode })}>&gt;</NavLink>
            </li>
          </>
        ) : (
          <li>&gt;</li>
        )}
        {page < lastPage ? (
          <li>
            <NavLink href={pageLink({ page: lastPage, baseUrl, mode })}>&gt;&gt;</NavLink>
          </li>
        ) : (
          <li>&gt;&gt;</li>
        )}
        <li className="hr" />
        {pages.map((val) =>
          val === 0 ? (
            <li key={`${page}_${val}_separator`} className="hr" />
          ) : (
            <Page key={`${page}_${val}`} page={val} baseUrl={baseUrl} mode={mode} />
          ),
        )}
      </ul>
    </Wrapper>
  );
}
