import type { Cell } from '../article-processor/types';

type Node = {
  text?: string;
  children?: Node[];
};
const nodesToText = (nodes: Node[]): string => {
  let text: string = '';
  for (let i = 0; i < nodes.length; i += 1) {
    const node = nodes[i];
    if (node?.text) {
      text += node?.text;
    }
    if (node?.children?.length) {
      text += nodesToText(node.children);
    }
  }

  return text;
};

const editorSlateToText = (article: Cell | Cell[]): string => {
  let nodes: Array<any>;
  if (Array.isArray(article)) {
    throw new Error('Todo get first slate ;)');
  } else {
    nodes = article.content.state.slate;
  }

  return nodesToText(nodes).replace(/\s+/g, ' ');
};

export default editorSlateToText;
