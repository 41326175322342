import styled, { css } from 'styled-components';
import React from 'react';
import { DEVICE_SIZE, mq } from '@marty-js/design/src/utils/mq';
import { UndecoratedNavLink } from '@marty-js/design/src/atoms/link';

export type BreadcrumbItem = {
  id?: string;
  url: string;
  title: string;
};

export type LegacyBreadcrumbItem = { [key: string]: { [label: string]: string } };

interface Props {
  breadcrumb: BreadcrumbItem[] | LegacyBreadcrumbItem[];
  isFullWidth?: boolean;
}

const BreadcrumbList = styled.ul`
  align-items: center;
  display: flex;
  grid-column: 2 / 3;
  list-style: none;
  max-width: calc(100vw - 40px);
  overflow: hidden;
  padding: 15px 0;
  white-space: nowrap;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      grid-column: 2 / 9;
      padding: 15px 0 15px 0;

      &.mod-full-width {
        grid-column: 1 / 10;
      }
    `,
  )}

  li {
    font-size: 16px;
    color: var(--theme-color-primary);

    &:after {
      content: '/';
      color: #99a4ba;
      margin-right: 4px;
      margin-left: 4px;
    }

    &:last-child {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:after {
        display: none;
      }
    }
  }
`;

const BreadcrumbLink = styled(UndecoratedNavLink)``;

export const Breadcrumb = React.forwardRef<HTMLUListElement, Props>(({ breadcrumb, isFullWidth = false }, ref) => {
  return (
    <BreadcrumbList id="breadcrumb-list" ref={ref} className={isFullWidth ? 'mod-full-width' : ''}>
      {breadcrumb?.map((breadcrumbItem, index: number) => (
        <li key={index}>
          {breadcrumbItem.url ? (
            <BreadcrumbLink href={breadcrumbItem.url}>{breadcrumbItem.title}</BreadcrumbLink>
          ) : (
            breadcrumbItem.title
          )}
        </li>
      ))}
    </BreadcrumbList>
  );
});
