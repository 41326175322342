import styled, { css } from 'styled-components';
import { DEVICE_SIZE, mq } from '../utils/mq';

export const Paragraph = styled.p`
  font-size: 16px;
  line-height: 1.62;
  margin: 0 0 15px;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      font-size: 18px;

      .chapo & {
        font-size: 20px;
        line-height: 32px;
      }
    `,
  )}
`;
